<template>
  <div class="contact-icons">
    <component v-for="key in keys" :is="icon(key)" class="icon" />
  </div>
</template>

<script setup>
import { IconLink } from '@tabler/icons-vue'
import IconTelegram from '~/components/icons/IconTelegram'
import IconVk from '~/components/icons/IconVk'
import IconLinkedIn from '~/components/icons/IconLinkedIn'

const props = defineProps({
  keys: Array
})

function icon (key) {
  return {
    tg: IconTelegram,
    vk: IconVk,
    in: IconLinkedIn,
    other: IconLink
  }[key]
}
</script>

<style scoped lang="scss">
.contact-icons {
  display: inline-flex;
  flex-shrink: 0;
  gap: 4px;
  align-items: center;

  .icon {
    width: 18px;
    height: 18px;
  }
}
</style>
