<template>
  <ChipList>
    <Chip
      icon
      v-for="contact in contacts"
      :key="contact.id"
      :href="contact.url"
      target="_blank"
      @click.stop
    >
      <component v-if="contact.icon" :is="contact.icon" class="icon" />
      <span>{{ contact.name }}</span>
    </Chip>
  </ChipList>
</template>

<script setup>
import { IconLink } from '@tabler/icons-vue'
import IconTelegram from '~/components/icons/IconTelegram'
import IconVk from '~/components/icons/IconVk'
import IconLinkedIn from '~/components/icons/IconLinkedIn'

const props = defineProps({
  contacts: Object
})

const contacts = computed(
  () => typeof props.contacts === 'object' && props.contacts !== null
    && Object.keys(props.contacts)
    .filter(k => ['tg', 'vk', 'in', 'other'].includes(k) && !!props.contacts[k])
    .map(k => ({
      id: k,
      icon: {
        tg: IconTelegram,
        vk: IconVk,
        in: IconLinkedIn,
        other: IconLink
      }[k],
      name: {
        tg: 'Telegram',
        vk: 'VK',
        in: 'LinkedIn',
        other: props.contacts[k].replace(/^https?:\/\//i, "")
      }[k],
      url: props.contacts[k]
    }))
)
</script>

<style scoped lang="scss">

</style>
